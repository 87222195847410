import { Controller } from "@hotwired/stimulus";
class src_default extends Controller {
  connect() {
    if (!this.hasUrlValue) {
      console.error("[stimulus-content-loader] You need to pass an url to fetch the remote content.");
      return;
    }
    this.hasLazyLoadingValue ? this.lazyLoad() : this.load();
  }
  disconnect() {
    this.stopRefreshing();
  }
  load() {
    this.fetch();
    if (this.hasRefreshIntervalValue) {
      this.startRefreshing();
    }
  }
  lazyLoad() {
    const options = {
      threshold: this.lazyLoadingThresholdValue,
      rootMargin: this.lazyLoadingRootMarginValue
    };
    const observer = new IntersectionObserver((entries, observer2) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.load();
          observer2.unobserve(entry.target);
        }
      });
    }, options);
    observer.observe(this.element);
  }
  fetch() {
    fetch(this.urlValue).then((response) => response.text()).then((html) => {
      this.element.innerHTML = html;
      if (this.loadScriptsValue) {
        this.loadScripts();
      }
    });
  }
  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.fetch();
    }, this.refreshIntervalValue);
  }
  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
  loadScripts() {
    this.element.querySelectorAll("script").forEach((content) => {
      const script = document.createElement("script");
      script.innerHTML = content.innerHTML;
      document.head.appendChild(script).parentNode.removeChild(script);
    });
  }
}
src_default.values = {
  url: String,
  lazyLoading: Boolean,
  lazyLoadingThreshold: Number,
  lazyLoadingRootMargin: {
    type: String,
    default: "0px"
  },
  refreshInterval: Number,
  loadScripts: Boolean
};
export { src_default as default };
